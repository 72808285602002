export const CircleDollarSVGIcon = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
    <radialGradient id="myGradient">
      <stop offset="10%" stopColor="gold" />
      <stop offset="95%" stopColor="red" />
    </radialGradient>
  </defs>
    {/* <circle cx="8.5" cy="8" r="7"  fill="url('#myGradient')" opacity="0.5" /> */}
    <path
      d="M9.125 4.625C9.4375 4.65625 9.75 4.75 10.0312 4.84375C10.0938 4.84375 10.1562 4.875 10.2188 4.875C10.5625 5 10.7812 5.375 10.6875 5.71875C10.5938 6.09375 10.2188 6.28125 9.84375 6.1875C9.8125 6.1875 9.75 6.15625 9.71875 6.15625C9.5 6.09375 9.28125 6.03125 9.0625 5.96875C8.625 5.90625 8.15625 5.9375 7.78125 6.09375C7.4375 6.25 7.15625 6.625 7.53125 6.875C7.84375 7.0625 8.21875 7.15625 8.5625 7.25C8.65625 7.28125 8.71875 7.3125 8.78125 7.3125C9.28125 7.46875 9.90625 7.625 10.375 7.96875C10.9688 8.375 11.25 9.0625 11.125 9.78125C11 10.4688 10.5 10.9375 9.9375 11.1562C9.6875 11.2812 9.40625 11.3438 9.125 11.375V11.8438C9.125 12.2188 8.84375 12.5 8.46875 12.5C8.09375 12.5 7.78125 12.2188 7.78125 11.8438V11.2812C7.34375 11.1875 6.875 11.0625 6.4375 10.9062C6.09375 10.7812 5.90625 10.4062 6.03125 10.0625C6.125 9.6875 6.53125 9.5 6.875 9.625C6.9375 9.65625 7.03125 9.6875 7.09375 9.6875C7.46875 9.8125 7.8125 9.9375 8.1875 10C8.71875 10.0938 9.125 10.0312 9.40625 9.90625C9.78125 9.78125 9.96875 9.3125 9.59375 9.0625C9.28125 8.84375 8.90625 8.75 8.53125 8.65625C8.4375 8.625 8.375 8.625 8.3125 8.59375C7.84375 8.46875 7.25 8.28125 6.8125 8C6.1875 7.625 5.875 6.96875 6.03125 6.25C6.15625 5.5625 6.6875 5.125 7.25 4.875C7.40625 4.78125 7.59375 4.75 7.78125 4.6875V4.1875C7.78125 3.8125 8.09375 3.5 8.46875 3.5C8.84375 3.5 9.125 3.8125 9.125 4.1875V4.625ZM16.5 8C16.5 12.4375 12.9062 16 8.5 16C4.0625 16 0.5 12.4375 0.5 8C0.5 3.59375 4.0625 0 8.5 0C12.9062 0 16.5 3.59375 16.5 8ZM8.5 1.5C4.90625 1.5 2 4.4375 2 8C2 11.5938 4.90625 14.5 8.5 14.5C12.0625 14.5 15 11.5938 15 8C15 4.4375 12.0625 1.5 8.5 1.5Z"
      fill="currentColor"
    />
  </svg>
);
