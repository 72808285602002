import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
} from "@chakra-ui/react";
import { LemListFamilyIcon } from "./icons/LemListFamilyIcon";
import { LemSwitcherMenuItem } from "./LemSwitcherMenuItem";
import { TaplioIcon } from "./icons/TaplioIcon";
import { LemWarmIcon } from "./icons/LemWarmIcon";
import { LemListIcon } from "./icons/LemListIcon";
import { MenuSVG } from "./icons/MenuSVG";
import { TweetHunterIcon } from "./icons/TweetHunterIcon";
import * as analytics from "utils/analytics";
import lemcalred from "./icons/lemcalred.png"
import lemcalwhite from "./icons/lemcalwhite.png"
import Image from 'next/image'

export const LemSwitcher = () => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu variant="switcher" autoSelect={false}>
      <MenuButton
        as={IconButton}
        {...{
          variant: "action",
          // variant: "invisible",
          position: "fixed",
          top: [3, 3, 4],
          right: [3, 3, 6],
          fontSize: "24px",
        }}
        aria-label="App Switcher"
        icon={<MenuSVG />}
        zIndex={999}
        onClick={(e) => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            console.log("hit lemapp swticher", e);
            analytics.log("hit_lemapp", { from: "taplio" });
          }
        }}
      />

      <MenuList {...{ zIndex: 999, maxW: ["320px", "none"] }}>
        <LemSwitcherMenuItem
          {...{
            title: "Tweet Hunter",
            subTitle: "Build & monetize your Twitter audience",
            icon: <TweetHunterIcon />,
            href: "https://app.tweethunter.io/",
            current: true,
            // title: "Taplio",
            // subTitle: "Grow your personal brand on LinkedIn",
            // icon: <TaplioIcon />,
            // href: undefined,
            // current: true
          }}
        />
        {/* <MenuDivider/> */}
        {[
          // {
          //   title: "Taplio",
          //   subTitle: "Grow your personal brand on LinkedIn",
          //   icon: <TaplioIcon />,
          //   href: undefined,
          //   // current: true,
          // },
          {
            title: "Taplio",
            subTitle: "Grow your personal brand on LinkedIn",
            icon: <TaplioIcon />,
            href: "https://app.taplio.com/",
          },
          {
            title: "lemlist",
            subTitle: "Start conversations that get replies",
            icon: <LemListIcon />,
            href: "https://app.lemlist.com/",
          },
          {
            title: "lemwarm",
            subTitle: "Keep your emails away from spam",
            icon: <LemWarmIcon />,
            href: "https://app.lemwarm.com/",
          },
          {
            title: "lemcal",
            subTitle: "Book meetings in the easiest way ever",
            icon: <Image
              src={lemcalred}
              alt="lemcal icon"
              width={"42"}
              height={"42"}
            // src={"/icons/lemcalred.png"}
            />,
            iconHover: <Image
              src={lemcalwhite}
              alt="lemcal icon"
              width={"42"}
              height={"42"}
            // src={"/icons/lemcalred.png"}
            />,
            href: "https://www.lemcal.com/",
          },
        ].map((item, mapIndex) => (
          <LemSwitcherMenuItem key={mapIndex} {...{ ...item }} />
        ))}
        <MenuDivider />
        <LemSwitcherMenuItem
          {...{
            hover: true,
            title: "lemfam",
            subTitle: "Join the 1% of Sales & Marketers",
            icon: <LemListFamilyIcon />,
            href: "https://community.lemlistfamily.com/",
          }}
        />
      </MenuList>
    </Menu>
  );
};
