import {
  Box, Flex,
  IconButton, Link, useColorModeValue, useMediaQuery, VisuallyHidden
} from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { Logo } from "components/logo";
import { LogoNew, SvgLogoIcon } from "components/logo/LogoNew";
import { FiMenu } from "react-icons/fi";
import { setInLocalStorage } from "utils/helpers";
import { SidebarDivider } from "./SidebarDivider";

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  isMinimalist?: boolean;
}

export function Header({ navSize, changeNavSize, isMinimalist=false }: Props) {
  const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const screenWidth = useWindowWidth();

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent={navSize == "large" ? "start" : "center"}
        height="66px"
      >
        {/* becomes the menu button when the screen is too small */}
        <IconButton
          aria-label="Toggle Menu"
          icon={<SvgLogoIcon />}
          onClick={() => {
            let newSize: "large" | "small" =
              navSize === "large" ? "small" : "large";
            changeNavSize(newSize);
            setInLocalStorage("navSize", newSize);
          }}
          position={"absolute"}
          top={"16px"}
          left={"13px"}
          variant="invisible"
          justifyContent={"start"}
          w={"34px"}
          maxW={"34px"}
          minW={"34px"}
          h={"34px"}
          maxH={"34px"}
          minH={"34px"}
        />
        {/* displays when sidebar is expanded (navSize === large) */}
        <Link
          display={navSize == "small" ? "none" : "flex"}
          href="/queue"
          _hover={{ textDecoration: "none" }}
          alignSelf="end"
          position={"absolute"}
          top={"22px"}
          left={"56px"}
        >
          <VisuallyHidden>Taplio</VisuallyHidden>
          <LogoNew
            textOnly
            mt={"3px"}
          />
        </Link>
      </Flex>
      <SidebarDivider navSize={navSize}></SidebarDivider>
    </Box>
  );

  return (
    <Box>
      <Flex alignItems="center" p={2} mt="2" h="56px" justifyContent="center">
        <IconButton
          mr={navSize == "large" ? 3 : 0}
          aria-label="Toggle Menu"
          display={navSize === "small" ? "flex" : isTooSmall ? "flex" : "none"}
          icon={<FiMenu />}
          onClick={() => {
            let newSize: "large" | "small" =
              navSize === "large" ? "small" : "large";
            changeNavSize(newSize);
            setInLocalStorage("navSize", newSize);
          }}
          bg={useColorModeValue("gray.50", "gray.600")}
          position={isTooSmall ? "absolute" : "unset"}
          left="3"
        />
        <Link display={navSize == "small" ? "none" : "flex"} href="/queue" _hover={{textDecoration: "none"}}>
          <VisuallyHidden>Tweet Hunter</VisuallyHidden>
          <Logo overrideSmallerThan600={true} h="8" preserveText={true} />
        </Link>
      </Flex>
    </Box>
  );
}
