import React from "react";
import { Icon } from "@chakra-ui/react";

export const MenuSVG = ({ ...props }) => (
  <Icon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="2.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="9.5" y="2.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="16.5" y="2.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="2.5" y="9.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="9.5" y="9.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="16.5" y="9.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="2.5" y="16.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="9.5" y="16.5" width="5" height="5" rx="1" fill="currentColor" />
      <rect x="16.5" y="16.5" width="5" height="5" rx="1" fill="currentColor" />
    </svg>
  </Icon>
);
