import { Icon } from "@chakra-ui/react";

export const UniversitySVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9375 4.03125C16.2812 4.21875 16.5 4.5625 16.5 4.9375C16.5 5.53125 16 6 15.4062 6H1.5625C0.96875 6 0.5 5.53125 0.5 4.9375C0.5 4.5625 0.6875 4.21875 1.03125 4.03125L8.125 0.09375C8.34375 0 8.625 0 8.84375 0.09375L15.9375 4.03125ZM7.5 4C7.5 3.46875 7.9375 3 8.5 3C9.03125 3 9.5 3.46875 9.5 4C9.5 4.1875 9.4375 4.375 9.34375 4.5H13.7188L8.5 1.625L3.25 4.5H7.625C7.53125 4.375 7.5 4.1875 7.5 4ZM2.5 7H4V12H6V7H7.5V12H9.5V7H11V12H13V7H14.5V12H14.75C15.1562 12 15.5 12.3438 15.5 12.75C15.5 13.1875 15.1562 13.5 14.75 13.5H2.5C2.0625 13.5 1.75 13.1875 1.75 12.75C1.75 12.3438 2.0625 12 2.5 12V7ZM15.75 14.5C16.1562 14.5 16.5 14.8438 16.5 15.25C16.5 15.6875 16.1562 16 15.75 16H1.5C1.0625 16 0.75 15.6875 0.75 15.25C0.75 14.8438 1.0625 14.5 1.5 14.5H15.75Z"
      fill="currentColor"
    />
  </svg>
);
