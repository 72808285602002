import {useEffect, useState} from "react";
import { 
    Box,
    HStack,
    theme,
    Text,
    Heading,
    useTimeout,
    useColorModeValue,
} from "@chakra-ui/react";
import { BiAlarmExclamation } from 'react-icons/bi';
import { BiErrorAlt } from 'react-icons/bi';

export const NoticePanel = ({
    title = "" as any, 
    body,
    icon = <BiAlarmExclamation />,
    type = "info",
}) => {

    const [refresh, refresher] = useState({});

    useEffect(() => {
        setTimeout(() => refresher({}), 500);
    }, []);

    let bg = useColorModeValue(theme.colors.gray[300],theme.colors.gray[700]);
    let bodyTextColor = useColorModeValue(theme.colors.gray[700],theme.colors.gray[300]);
    if (type == "error") bg = useColorModeValue(theme.colors.red[100],theme.colors.red[800]);
    if (type == "danger") bg = useColorModeValue(theme.colors.red[400],theme.colors.red[300]);

    return(
        ( (body)?
            <Box 
                bg={bg}
                w='100%' p={3} 
                pl={6} 
                borderBottom={"solid 1px " + useColorModeValue(theme.colors.gray[50],theme.colors.gray[800])}
            >
                {
                    title &&
                        <HStack> 
                            {type == "error" ? <BiErrorAlt /> : icon}
                            <Heading size="sm"> 
                            {title}
                            </Heading>
                        </HStack> 
                }
                <HStack>
                    <Box>
                    {
                        Array.isArray(body) ? (
                            body.map((item, index) => (
                                <Text color={bodyTextColor} key={index} sx={{fontSize : "13px"}} pl={6} mt={1}>
                                    {item}
                                </Text>
                        ))
                        ) : (
                            <Box 
                                color={bodyTextColor} 
                                sx={{fontSize : "13px"}}
                                pl={6}
                                mt={1}
                            >
                                {body} 
                            </Box>
                        )
                    }
                    </Box>
                </HStack>
            </Box>
            :
            null
        )
    );
}