import { useSession } from "next-auth/client";
import {
  Flex,
  Image,
  Heading,
  Text,
  Box,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from "@chakra-ui/react";
import { getAccount } from "utils/sessionHelper";
import { AccountSwitcher } from "components/account";

interface Props {
  navSize: "small" | "large";
}

export function Account({ navSize }: Props) {
  const [session, loading] = useSession();
  const {
    isOpen: isOpenGhostData,
    onOpen: onOpenGhostData,
    onClose: onCloseGhostData,
  } = useDisclosure();

  if (loading) {
    return null;
  }

  console.log(getAccount(session)?.name);
  
  return (
    <>
      <Flex m={2} justifyContent={"space-between"}>
        {/* small user icon (navSize small) */}
        <Flex
          // mt={4}
          // mb={2}
          my="9px"
          h="40px"
          w="40px"
          justifyContent={navSize == "large" ? "flex-start" : "center"}
          display={navSize == "small" ? "flex" : "none"}
        >
          <Image
            w="10"
            h="10"
            rounded="3xl"
            objectFit="cover"
            src={getAccount(session)?.image}
            fallbackSrc="/emptyProfile.png"
            ignoreFallback={false}
            onClick={() => {
              if (
                (session?.user?.data?.isGhost ||
                  session?.user?.data?.isAdmin) &&
                getAccount(session)?.ghostData?.length
              ) {
                onOpenGhostData();
              }
            }}
          />
          {/* <Flex
            display={navSize == "small" ? "none" : "flex"}
            flexDirection="column"
            ml={2}
            justifyContent="center"
          >
            <Heading noOfLines={1} wordBreak={"break-all"} w="138px" as="h3" size="sm">
              {getAccount(session)?.name}
            </Heading>
            {getAccount(session)?.twUserName && (
              <Text
                noOfLines={1} wordBreak={"break-all"}
                w="140px"
                color={useColorModeValue("gray.400", "gray.300")}
              >
                @{getAccount(session)?.twUserName}
              </Text>
            )}
          </Flex> */}
        </Flex>
        {/* navsize === large */}
        <Box display={navSize == "small" ? "none" : "flex"} w="100%">
          {!loading && session?.user && (
            <AccountSwitcher
              disableMenu={false}
              disablePhoto={true}
              user={session?.user}
            />
          )}
        </Box>
      </Flex>
      {/* ghost and admin */}
      {(session?.user?.data?.isGhost || session?.user?.data?.isAdmin) &&
        getAccount(session)?.ghostData?.length && (
          <Modal
            isOpen={isOpenGhostData}
            onClose={onCloseGhostData}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent maxH="800px" overflow="scroll">
              <ModalHeader>
                Information about @{getAccount(session)?.twUserName}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={5}>
                {getAccount(session)?.ghostData.map((data, index) => (
                  <Box mt={5} key={index}>
                    <Heading fontSize="sm">{data.title}</Heading>
                    <Text>{data.data}</Text>
                  </Box>
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
    </>
  );

  return (
    <>
      {/* <Flex> */}
        {/* small user icon (navSize small) */}
      <Flex
        my="16px"
        mx="10px"
          h="40px"
          w="40px"
          justifyContent={navSize == "large" ? "flex-start" : "center"}
          display={navSize == "small" ? "flex" : "none"}
        >
          <Image
            w="10"
            h="10"
            rounded="3xl"
            objectFit="cover"
            src={getAccount(session)?.image}
            fallbackSrc="/emptyProfile.png"
            ignoreFallback={false}
            onClick={() => {
              if (
                (session?.user?.data?.isGhost ||
                  session?.user?.data?.isAdmin) &&
                getAccount(session)?.ghostData?.length
              ) {
                onOpenGhostData();
              }
            }}
          />
        </Flex>
        {/* navsize === large: account switcher */}
        <Box display={navSize == "small" ? "none" : "flex"}
         justifyContent={navSize == "large" ? "space-between" : "space-between"}
          m={2}
        >
          {!loading && session?.user && (
            <AccountSwitcher
              disableMenu={false}
              disablePhoto={true}
              user={session?.user}
              {...{
                navSize,
              }}
            />
          )}
        </Box>
      {/* </Flex> */}
      {(session?.user?.data?.isGhost || session?.user?.data?.isAdmin) &&
        getAccount(session)?.ghostData?.length && (
          <Modal
            isOpen={isOpenGhostData}
            onClose={onCloseGhostData}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent maxH="800px" overflow="scroll">
              <ModalHeader>
                Information about @{getAccount(session)?.twUserName}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={5}>
                {getAccount(session)?.ghostData.map((data, index) => (
                  <Box mt={5} key={index}>
                    <Heading fontSize="sm">{data.title}</Heading>
                    <Text>{data.data}</Text>
                  </Box>
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
          )}
        {/* </Flex> */}
    </>
  );
  return (
    <>
      <Flex
        w="95%"
        h="45px"
        justifyContent={navSize == "large" ? "space-between" : "space-between"}
        mb={2}
        mt={2}
        ml={navSize == "large" ? 2 : 0}
      >
        <Flex
          h="40px"
          justifyContent={navSize == "large" ? "flex-start" : "center"}
        >
          <Image
            w="10"
            h="10"
            rounded="3xl"
            objectFit="cover"
            src={getAccount(session)?.image}
            fallbackSrc="/assets/resources/emptyProfile.png"
            ignoreFallback={false}
            onClick={() => {
              if (
                (session?.user?.data?.isGhost ||
                  session?.user?.data?.isAdmin) &&
                getAccount(session)?.ghostData?.length
              ) {
                onOpenGhostData();
              }
            }}
          />
          <Flex
            display={navSize == "small" ? "none" : "flex"}
            flexDirection="column"
            ml={2}
          >
            <Heading noOfLines={1} wordBreak="break-all" w="140px" as="h3" size="sm">
              {getAccount(session)?.name}
            </Heading>
            {getAccount(session)?.twUserName && (
              <Text
                noOfLines={1} wordBreak="break-all"
                w="140px"
                color={useColorModeValue("gray.400", "gray.300")}
              >
                @{getAccount(session)?.twUserName}
              </Text>
            )}
          </Flex>
        </Flex>
        <Box display={navSize == "small" ? "none" : "flex"}>
          {!loading && session?.user && (
            <AccountSwitcher
              disableMenu={false}
              disablePhoto={true}
              user={session?.user}
            />
          )}
        </Box>
      </Flex>
      {(session?.user?.data?.isGhost || session?.user?.data?.isAdmin) &&
        getAccount(session)?.ghostData?.length && (
          <Modal
            isOpen={isOpenGhostData}
            onClose={onCloseGhostData}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent maxH="800px" overflow="scroll">
              <ModalHeader>
                Information about @{getAccount(session)?.twUserName}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={5}>
                {getAccount(session)?.ghostData.map((data, index) => (
                  <Box mt={5} key={index}>
                    <Heading fontSize="sm">{data.title}</Heading>
                    <Text>{data.data}</Text>
                  </Box>
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
    </>
  );
}
