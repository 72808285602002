import React, { ReactNode, useState, useEffect, ReactElement } from 'react';
import {
    useMediaQuery,
    IconButton,
    Box,
    useColorModeValue,
    Tooltip,
    Flex,
    useDisclosure,
    Text,
    Icon,
    BoxProps,
    TextProps,
    FlexProps,
    Button,
    Link,
    Center,
    Heading
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { IconType } from "react-icons/lib";
import * as analytics from "../../utils/analytics";
import { useSession } from "next-auth/client";
import Sidebar from "components/sidebar";
import { CustomHead } from "components/custom-head";
import { useRouter } from "next/dist/client/router";
import { FiFeather } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { RiFullscreenLine } from 'react-icons/ri';
import { motion } from "framer-motion"
import { TweetContext } from "../../context/tweetContext";
import { OnBoardPanel } from "../onboard-panel";
import { getAccount, updateUser, stopImpersonating, getAuthUrl } from "../../utils/sessionHelper";
import { setInLocalStorage } from "../../utils/helpers";
import { useMiscellaneousContext } from "context/miscellaneousContext"
import { useFirebaseUser } from "utils/useFirebaseUser";
import { getPlan } from 'controllers/subscription';
import { EnterpriseDiscount } from "components/popups/enterprise-discount";
import { useEnterpriseCompaign } from "hooks/useEnterpriseCampaign";
import { useWindowWidth } from '@react-hook/window-size';
import { NoticePanel } from './noticepanel';
import { MainContext } from 'context/mainContext';
import NextLink from "next/link";
import { useChatContext } from "context/chatContext";
import { LemSwitcher } from './LemSwitcher';
import { textStyle, variant } from 'theme/names';
import { BsLightningChargeFill } from 'react-icons/bs';
import { WriteAPostSVGIcon } from 'components/sidebar/icons/WriteAPostSVGIcon';
import { InfoIconSVG } from 'components/icons/InfoIconSVG';
import { isAllowed } from "controllers/subscription";
import { IconButtonAi } from 'components/common/AiButton/AiButton';
interface Props {
    children: ReactNode;
    forceCloseScheduler?: boolean;
    isMinimalist?: boolean;
    disableSidebar?: boolean;
    leftIcon?: ReactElement;
    title?: ReactNode | string;
    description?: any;
    infoIcon?: IconType | typeof Icon;
    infoTooltipText?: string;
    contentOnRightOfTitle?: ReactNode;
    contentBesidesTitle?: ReactNode;
    mainWrapperProps?: BoxProps;
    descriptionWrapperProps?: TextProps;
    titleIconWrapperProps?: FlexProps;
    titleRowWrapperProps?: FlexProps;
    noIndex?: boolean;
    disableHeader?: boolean;
    disableAlerts?: boolean;
}

export function Page({
    children,
    forceCloseScheduler = false,
    disableSidebar = false,
    leftIcon = undefined,
    title,
    description = "",
    infoIcon: InfoIcon = InfoOutlineIcon,
    infoTooltipText = "",
    contentOnRightOfTitle = null,
    contentBesidesTitle = null,
    mainWrapperProps = {},
    descriptionWrapperProps = {},
    titleIconWrapperProps = {},
    titleRowWrapperProps = {},
    isMinimalist = false,
    noIndex = true,
    disableHeader = false,
    disableAlerts = false,
}: Props) {
    const [session, loading] = useSession();

    const screenWidth = useWindowWidth();
    const isTooSmall = screenWidth < 800;
    const isTooSmallFullScreen = screenWidth < 1300;
    // const [isTooSmall] = useMediaQuery("(max-width: 800px)");
    // const [isTooSmallFullScreen] = useMediaQuery("(max-width: 1300px)");

    const [navSize, changeNavSize] = React.useState<"small" | "large">("small");
    const [pl, setPl] = React.useState("0px");
    const [pr, setPr] = React.useState("0px");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const tweetContext: any = React.useContext(TweetContext);
    const chatContext: any = useChatContext();
    const miscellaneousContext: any = useMiscellaneousContext();
    const isAuthenticated = useFirebaseUser();
    const { activeCampaign } = useEnterpriseCompaign();
    const router = useRouter();

    const isPayingCustomer = !getAccount(session)?.subStatus?.includes("FreeTrial");
    const isNotEnterpriseUser = !getPlan(session?.user).includes("enterprise");
    const disableScheduler = false;
    const mainContext = React.useContext<any>(MainContext);

    useEffect(() => {
        if (session && isAuthenticated && !miscellaneousContext.hasShownCampaignPopup) {
            if (isPayingCustomer && isNotEnterpriseUser && activeCampaign && !getAccount(session)?.hideCampaignPopup && !router.pathname.includes("pricing")) {
                // user is not on free trial and is not an enterprise user
                setTimeout(() => {
                    miscellaneousContext.setHasShownCampaignPopup(true);
                    onOpen();
                }, 4000)
            }
        }
    }, [session, isAuthenticated, activeCampaign, isPayingCustomer])

    React.useEffect(() => {
        analytics.init(session);
    }, [session]);

    React.useEffect(() => {
        changeNavSize("small");

        let savedSchedulerStatus = localStorage.getItem('isSchedulerOpen');

        if (!isTooSmall && savedSchedulerStatus !== null && !forceCloseScheduler) {
            tweetContext.setIsOpen(savedSchedulerStatus == "true");
        }
        else if (!isTooSmall && !forceCloseScheduler) {
            tweetContext.setIsOpen(true);
        }
        else {
            tweetContext.setIsOpen(false);
        }

        if (["/pricing", "/engage", "/onboarding", "/tweets", "/publictweet", "/public", "/onboardnew", "/subscribe", "/sign", "/thtweets"].includes(router.pathname)) {
            tweetContext.setIsOpen(false);
        }
    }, []);

    React.useEffect(() => {
        if (!isTooSmall) {
            if (tweetContext.isOpen) {
                setPr("400px");
            } else if (miscellaneousContext.isPersonSidebarOpen) {
                setPr("350px")
            } else {
                setPr("0px");
            }
        } else {
            setPr("0px");
        }

        //@ts-ignore
        setInLocalStorage('isSchedulerOpen', tweetContext.isOpen);
    }, [tweetContext.isOpen, miscellaneousContext.isPersonSidebarOpen, screenWidth]);

    React.useEffect(() => {
        if (!isTooSmall) {
            setPl("60px");
        }
        else {
            setPl("0px");
        }
    }, [navSize, screenWidth]);

    React.useEffect(() => {
        try {
            if (isAuthenticated && typeof window !== "undefined") {
                // @ts-ignore
                window.Intercom("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "nlu9qthh",
                    name: session?.user?.data?.name, // Full name
                    email: session?.user?.data?.email, // Email address
                    user_id: session?.user?.data?.id, // User ID
                    hide_default_launcher: true,
                    brand: "Tweet Hunter Ticket/Message",
                    subject: "Tweet Hunter Ticket/Message",
                    product: "Tweet Hunter"
                });
            }
        }
        catch (e) {
            console.error("Error in intercom init: " + e.message);
        }
    }, [isAuthenticated])

    const handleCloseClick = async () => {
        await updateUser(session, { hideCampaignPopup: true });
    }

    let SchedulerBtnPos: any = {};
    if (isTooSmall) {
        SchedulerBtnPos.left = "20px";
    } else {
        SchedulerBtnPos.left = "unset"
        SchedulerBtnPos.right = tweetContext.isOpen ? "382px" : "20px";
    }

    const showOnboarding = getAccount(session)?.onboarding?.showPanel && !["/pricing"].includes(router.pathname);
    const iconGrayBg = useColorModeValue("border.lightMode.light", "border.darkMode.light");
    const iconTwitterBlueBg = useColorModeValue("blue.400", "blue.200");
    const glowIcon = useColorModeValue(true, false);

    // let urlReauthTwitter = getAccount(session)?.id === getAccount(session)?.idAccount ? "/sign?thApp=" + pickRandomTwitterApp().thApp : getAuthUrl(session);
    let urlReauthTwitter = getAuthUrl(session);
    // console.log('urlReauthTwitter:', urlReauthTwitter);

    const schedulerWidth = 400;
    const leftSideNavWidth = 60;
    const chatAssistPopupMaxWidth = Math.min(900, screenWidth);
    const wontFit = (schedulerWidth + leftSideNavWidth + chatAssistPopupMaxWidth) > screenWidth;

    return (
        <Box>
            <CustomHead noIndex={noIndex} />
            {
                !disableSidebar && (
                    <Sidebar changeNavSize={changeNavSize} navSize={navSize} credits={session?.user?.data?.credits} creditsAuto={session?.user?.data?.creditsAuto} isMinimalist={isMinimalist} />
                )
            }
            <motion.div
                animate={{
                    paddingLeft: pl,
                    paddingRight: pr,
                }}
                transition={{
                    duration: 0.1,
                    ease: "easeOut", // "linear" "easeIn", "easeOut", "easeInOut" "circIn", "circOut", "circInOut" "backIn", "backOut", "backInOut" "anticipate"
                }}
                style={{
                    width: "100%",
                }}
            >
                {
                    !disableAlerts && (session?.user?.data?.idImpersonating) &&
                    // (true) &&
                    <NoticePanel type="danger" title={<>You are impersonating <Link textDecoration="underline" isExternal href={"https://twitter.com/" + session?.user?.data?.impersonatingTwUserName}>@{session?.user?.data?.impersonatingTwUserName}</Link> - {session?.user?.data?.impersonatingEmail}</>}
                        body={
                            <Flex alignItems="center" >
                                You are currently accessing an other user account - be careful with what you are doing
                                <Button
                                    size="sm"
                                    variant={"secondary"}
                                    ml={5}
                                    onClick={() => stopImpersonating(session)}
                                >
                                    Stop Impersonating
                                </Button>
                            </Flex>
                        } />
                }
                {/* {
                    // Alert system - global notification
                    // router?.pathname?.includes("/engage") && (
                        <NoticePanel
                            title="Issue resolved 🎉"
                            // type="error"
                            body={
                                <>
                                    The Twitter API has experienced issues and is now fully functional. Sorry for the inconvenience.
                                </>
                            }
                        />
                    // )
                } */}
                {
                    // !loading && (!getAccount(session)?.thApp || !getAccount(session)?.thWriteAccessToken || ["T_TWEETHUTLERBOT_AUTO_REPLY", "T_TWEETHUNTERWRITE"].includes(getAccount(session)?.thApp)) ? (
                    !disableAlerts && !loading && (!getAccount(session)?.thApp || !getAccount(session)?.thWriteAccessToken || getAccount(session)?.thApp !== "T_TWEETHUTLERBOT_AUTO_REPLY") ? (
                        // true ? (
                        // typeof window !== "undefined" ? (
                        <NoticePanel
                            title="Re-authorize Tweet Hunter"
                            // type={getAccount(session)?.thApp == "T_TWEETHUTLERBOT_AUTO_REPLY" ? "error" : "info"}
                            body={
                                <Flex alignItems="center">
                                    We have detected your Twitter token is invalid. Refresh it to use Tweet Hunter
                                    <Button
                                        ml={5}
                                        as="a"
                                        // href={getAccount(session)?.id === getAccount(session)?.idAccount ? "/sign?redirect=" + encodeURIComponent("/queue") + "&thApp=" + pickRandomTwitterApp().thApp : getAuthUrl(session)}
                                        href={urlReauthTwitter}
                                        // target={getAccount(session)?.id === getAccount(session)?.idAccount ? "_self" : "_blank"}
                                        target={"_blank"}
                                        size="sm"
                                        variant={"primary"}
                                    >Re-Authorize</Button>
                                </Flex>
                            }
                        />
                    ) : (
                        // !loading && (getAccount(session)?.thApp !== "CUSTOM") ? (
                        false ? (
                            <NoticePanel
                                title="Custom Authentication"
                                body={
                                    <Flex alignItems="center">
                                        We're still experiencing difficulties due to Twitter API changes. For the highest reliability, you can now use your custom Twitter authentication and get a free month of Tweet Hunter.
                                        <Button
                                            ml={5}
                                            size="sm"
                                            variant={"primary"}
                                            minW="230px"
                                            onClick={() => {
                                                mainContext.onOpenApiKeyAuth();
                                            }}
                                        >Setup Custom Authentication</Button>
                                    </Flex>
                                }
                            />
                        ) : (
                            <></>
                        )
                    )
                }
                {!disableAlerts && isAuthenticated && (!getAccount(session)?.keywords?.length || !getAccount(session)?.description) && (
                    // (true) &&
                    <NoticePanel
                        title="AI settings are not properly configured "
                        body={
                            <>
                                It seems your AI settings are not set. Please, <NextLink href={"/settings#search-settings"}><Text as="span" textDecoration="underline" >update them here</Text></NextLink> to improve the AI generation quality{" "}
                            </>
                        }
                    />
                )}
                {showOnboarding && <OnBoardPanel />}
                <LemSwitcher />
                {
                    !tweetContext.isFullModeOpen && (
                        <Box
                            maxWidth={"1200"}
                            margin={"auto"}
                            px={tweetContext.isOpen ? [8, 8, 8, 8, 16] : [4, 12, 16, 16, 16]}
                            py={[12, 12, 8]}
                            {...mainWrapperProps}
                        >
                            {
                                !disableHeader && (
                                    <Box>
                                        <Flex alignItems="center" justifyContent="space-between" w="100%" {...titleRowWrapperProps}>
                                            <Flex alignItems="center" justifyContent="flex-start" {...titleIconWrapperProps}>
                                                {leftIcon && <Box marginEnd={leftIcon ? 2 : 0}>
                                                    {leftIcon}
                                                </Box>}
                                                {title && <Text textStyle="h1.bold.standard">
                                                    {title}
                                                </Text>}
                                                {infoTooltipText && (
                                                    <Tooltip placement="right" hasArrow label={infoTooltipText}>
                                                        <Box ml={2} mt={1} >
                                                            {InfoIconSVG}
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {contentBesidesTitle}
                                            </Flex>
                                            {contentOnRightOfTitle}
                                        </Flex>
                                        <Box>
                                            <Text
                                                textStyle={textStyle["body.medium.light"]}
                                                mt={4}
                                                {...descriptionWrapperProps}
                                            >
                                                {description}
                                            </Text>
                                        </Box>
                                    </Box>
                                )
                            }
                            {children}
                        </Box>
                    )
                }
                {
                    !disableScheduler && (!isTooSmall || navSize == "small") && session?.user?.uid && (
                        // icon buttons
                        <motion.div
                            animate={SchedulerBtnPos}
                            transition={{
                                duration: 0.1,
                                ease: "easeOut", // "linear" "easeIn", "easeOut", "easeInOut" "circIn", "circOut", "circInOut" "backIn", "backOut", "backInOut" "anticipate"
                            }}
                            style={{
                                position: "fixed",
                                bottom: "20px",
                                // right: "20px",
                                zIndex: chatContext?.isOpen && isTooSmall ? 100 : 200,
                                width: "48px",
                            }}
                        >
                            {!tweetContext.isIncognitoMode && isAllowed(session?.user, "chat") && (
                                <>
                                    <IconButtonAi {...{
                                        containerProps: {
                                            w: "48px",
                                            h: "48px",
                                            mb: "2",
                                            square: true,
                                        },
                                        iconProps: {
                                            width: "18px", height: "18px"
                                        },
                                        onClick: () => {
                                            console.log("click");

                                            if (chatContext?.isOpen) {
                                                chatContext?.onClose();
                                            } else {
                                                if (tweetContext?.isOpen && wontFit) {
                                                    tweetContext?.close();
                                                }
                                                chatContext?.onOpen();
                                            }
                                        },
                                        size: "lg",
                                    }} />
                                    {/* <AnimatedBg
                                        {...{
                                            w: "48px",
                                            h: "48px",
                                            mb: "2",
                                            square: true,
                                        }}
                                    >
                                        <IconButton
                                            variant={variant.Button.secondaryAI}
                                            w="48px"
                                            {...{
                                                border: "1px solid #A376BB22",
                                                bg: "#A577dd08",
                                                _hover: {
                                                    border: "1px solid #A376BB22",
                                                },
                                                _dark: {
                                                    borderColor: "#403278",
                                                },
                                            }}
                                            size="lg"
                                            aria-label="Open chat assist"
                                            icon={<AiIcon {...{ glow: glowIcon, width: "18px", height: "18px" }} />}
                                            // icon={<BsLightningChargeFill size={18} color="white" />}
                                            onClick={() => {
                                                if (chatContext?.isOpen) {
                                                    chatContext?.onClose();
                                                } else {
                                                    if (tweetContext?.isOpen && wontFit) {
                                                        tweetContext?.close();
                                                    }
                                                    chatContext?.onOpen();
                                                }
                                            }}
                                        />
                                    </AnimatedBg> */}
                                </>
                            )
                            }
                            {tweetContext.isOpen && tweetContext.isFullModeOpen && tweetContext.isIncognitoMode && <></>}
                            {tweetContext.isOpen && tweetContext.isFullModeOpen && !tweetContext.isIncognitoMode && (
                                <Flex direction={'column'} gap={2}>
                                    {
                                        !isTooSmallFullScreen &&
                                        <Tooltip aria-label='tooltip' label='Back to panel composer' placement='right'>
                                            <IconButton
                                                aria-label="Back to panel composer"
                                                variant={variant.Button.primary}
                                                // variant={variant.Button.secondary}
                                                // shadow="lg"
                                                size="lg"
                                                onClick={() => {
                                                    tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                                    tweetContext.setTextStates(tweetContext.refComposer.current.textStates());
                                                    tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                                }}
                                                icon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.25 10C4.65625 10 5 10.3438 5 10.75V14.25C5 14.6875 4.65625 15 4.25 15C3.8125 15 3.5 14.6875 3.5 14.25V11.5H0.75C0.3125 11.5 0 11.1875 0 10.75C0 10.3438 0.3125 10 0.75 10H4.25ZM9.75 6C9.3125 6 9 5.6875 9 5.25V1.75C9 1.34375 9.3125 1 9.75 1C10.1562 1 10.5 1.34375 10.5 1.75V4.5H13.25C13.6562 4.5 14 4.84375 14 5.25C14 5.6875 13.6562 6 13.25 6H9.75ZM4.25 1C4.65625 1 5 1.34375 5 1.75V5.25C5 5.6875 4.65625 6 4.25 6H0.75C0.3125 6 0 5.6875 0 5.25C0 4.84375 0.3125 4.5 0.75 4.5H3.5V1.75C3.5 1.34375 3.8125 1 4.25 1ZM13.25 10C13.6562 10 14 10.3438 14 10.75C14 11.1875 13.6562 11.5 13.25 11.5H10.5V14.25C10.5 14.6875 10.1562 15 9.75 15C9.3125 15 9 14.6875 9 14.25V10.75C9 10.3438 9.3125 10 9.75 10H13.25Z" fill="currentColor" />
                                                    </svg>
                                                }
                                            />
                                        </Tooltip>
                                    }
                                    <IconButton
                                        aria-label="close tweet composer"
                                        variant={variant.Button.primary}
                                        size="lg"
                                        onClick={() => {
                                            tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                            tweetContext.setIsOpen(!tweetContext.isOpen);
                                        }}
                                        icon={<Icon viewBox="0 0 14 11">
                                            <svg
                                                width="14"
                                                height="11"
                                                viewBox="0 0 14 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </Icon>}
                                    />
                                </Flex>
                            )}
                            {/* {tweetContext.isOpen && tweetContext.isFullModeOpen && ( */}
                            {tweetContext.isOpen && !tweetContext.isFullModeOpen && (
                                <Flex direction={'column'} gap={2}>
                                    {/* {
                                        !isTooSmallFullScreen && 
                                            <Tooltip aria-label='tooltip' label='Full screen tweet composer' placement='right'>
                                                <IconButton
                                                    aria-label="display full mode editor"
                                                    variant={variant.Button.primary}
                                                    //  variant={variant.Button.secondary}
                                                    //  shadow="lg"
                                                    size="lg"
                                                    onClick={() => {
                                                        tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                                        tweetContext.setTextStates(tweetContext.refComposer.current.textStates());
                                                        tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                                    }}
                                                    icon={<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.25 1C4.65625 1 5 1.34375 5 1.75C5 2.1875 4.65625 2.5 4.25 2.5H1.5V5.25C1.5 5.6875 1.15625 6 0.75 6C0.3125 6 0 5.6875 0 5.25V1.75C0 1.34375 0.3125 1 0.75 1H4.25ZM13.25 1C13.6562 1 14 1.34375 14 1.75V5.25C14 5.6875 13.6562 6 13.25 6C12.8125 6 12.5 5.6875 12.5 5.25V2.5H9.75C9.3125 2.5 9 2.1875 9 1.75C9 1.34375 9.3125 1 9.75 1H13.25ZM4.25 13.5C4.65625 13.5 5 13.8438 5 14.25C5 14.6875 4.65625 15 4.25 15H0.75C0.3125 15 0 14.6875 0 14.25V10.75C0 10.3438 0.3125 10 0.75 10C1.15625 10 1.5 10.3438 1.5 10.75V13.5H4.25ZM13.25 10C13.6562 10 14 10.3438 14 10.75V14.25C14 14.6875 13.6562 15 13.25 15H9.75C9.3125 15 9 14.6875 9 14.25C9 13.8438 9.3125 13.5 9.75 13.5H12.5V10.75C12.5 10.3438 12.8125 10 13.25 10Z" fill="currentColor"/>
                                                    </svg>
                                                    }
                                                />
                                            </Tooltip>
                                    } */}
                                    <Box id="close-div">
                                        <IconButton
                                            aria-label="close tweet composer"
                                            variant={variant.Button.primary}
                                            size="lg"
                                            onClick={() => {
                                                if (tweetContext.isPreviewOpen) {
                                                    tweetContext.setIsPreviewOpen(!tweetContext.isPreviewOpen);
                                                } else {
                                                    tweetContext.setIsOpen(!tweetContext.isOpen);
                                                }
                                            }}
                                            icon={<Icon viewBox="0 0 14 11">
                                                <svg
                                                    width="14"
                                                    height="11"
                                                    viewBox="0 0 14 11"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </Icon>}
                                        />
                                    </Box>
                                </Flex>
                            )}
                            {!tweetContext.isOpen && (
                                <IconButton
                                    aria-label="display tweet composer"
                                    variant={variant.Button.primary}
                                    size="lg"
                                    onClick={() => {
                                        if (chatContext?.isOpen && wontFit) {
                                            chatContext?.onClose();
                                        }
                                        tweetContext.setIsOpen(!tweetContext.isOpen);
                                    }}
                                    icon={<WriteAPostSVGIcon />}
                                />
                            )}
                            {/* {
                                tweetContext.isOpen ?
                                    tweetContext.isFullModeOpen ?
                                        tweetContext.isIncognitoMode ? (
                                            <></>
                                        ) : (
                                            <IconButton
                                                aria-label="close tweet composer"
                                                variant={variant.Button.primary}
                                                size="lg"
                                                onClick={() => {
                                                    tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                                    tweetContext.setIsOpen(!tweetContext.isOpen);
                                                }}
                                                icon={<Icon viewBox="0 0 14 11">
                                                <svg
                                                width="14"
                                                height="11"
                                                viewBox="0 0 14 11"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path
                                                    d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                                                    fill="currentColor"
                                                />
                                                </svg>
                                            </Icon>}
                                            />
                                        )
                                    :
                                        <Flex direction={'column'} gap={2}>
                                            {
                                                !isTooSmallFullScreen && 
                                                    <Tooltip aria-label='tooltip' label='Full screen tweet composer' placement='right'>
                                                        <IconButton
                                                            aria-label="display full mode editor"
                                                            variant={variant.Button.secondary}
                                                            shadow="lg"
                                                            size="lg"
                                                            onClick={() => {
                                                                tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                                                tweetContext.setTextStates(tweetContext.refComposer.current.textStates());
                                                                tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                                            }}
                                                            icon={<RiFullscreenLine />}
                                                        />
                                                    </Tooltip>
                                            }

                                            <Box id="close-div">
                                                <IconButton
                                                    aria-label="close tweet composer"
                                                    variant={variant.Button.primary}
                                                    size="lg"
                                                    onClick={() => {
                                                        if(tweetContext.isPreviewOpen){
                                                            tweetContext.setIsPreviewOpen(!tweetContext.isPreviewOpen);
                                                        }else{
                                                            tweetContext.setIsOpen(!tweetContext.isOpen);
                                                        }
                                                    }}
                                                    icon={<Icon viewBox="0 0 14 11">
                                                        <svg
                                                        width="14"
                                                        height="11"
                                                        viewBox="0 0 14 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                        <path
                                                            d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                                                            fill="currentColor"
                                                        />
                                                        </svg>
                                                    </Icon>}
                                                />
                                            </Box>
                                        </Flex>
                                :
                                    <IconButton
                                        aria-label="display tweet composer"
                                        variant={variant.Button.primary}
                                        size="lg"
                                        onClick={() => {
                                            if (chatContext?.isOpen && wontFit) {
                                                chatContext?.onClose();
                                            }
                                            tweetContext.setIsOpen(!tweetContext.isOpen);
                                        }}
                                        icon={ <WriteAPostSVGIcon />}
                                    />

                            } */}
                        </motion.div>
                    )
                }
            </motion.div>
            <EnterpriseDiscount isOpen={isOpen} onClose={onClose} onCloseClick={handleCloseClick} />
            {/* <Box
                position="fixed" bottom={5} left="50%" transform="translateX(-50%)"
                zIndex="10"
                pl={pl}
                pr={pr}
            >   
                <div dangerouslySetInnerHTML={{ __html: `<a href="https://www.producthunt.com/posts/tweet-hunter-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-tweet-hunter-1" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317360&theme=${useColorModeValue("light", "dark")}" alt="Tweet Hunter - AI powered Twitter growth for makers | Product Hunt" style="width: 180px; height: 40px;" width="180" height="40" /></a>` }} />
            </Box> */}

            {/* <Center
                position="fixed" bottom={5} left="50%" transform="translateX(-50%)"
                flexDir={"column"}
                zIndex="10"
                pl={pl}
                pr={pr}
            >   
                <Text mb={2} fontWeight={600}>Support Taplio on Product Hunt:</Text>
                <div dangerouslySetInnerHTML={{ __html: `<a href="https://www.producthunt.com/posts/taplio?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-taplio" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317360&theme=${useColorModeValue("light", "dark")}" alt="Tweet Hunter - AI powered Twitter growth for makers | Product Hunt" style="width: 180px; height: 40px;" width="180" height="40" /></a>` }} />
            </Center> */}

            {/* TO ACTIVATE - ERROR MESSAGE */}
            {/* <Box
                position="fixed" bottom={2} left="50%" transform="translateX(-50%)"
                zIndex="10"
                pl={pl}
                pr={pr}
            >   
                <Tag
                    colorScheme="red"
                    size="sm"
                    fontSize="xs"
                    fontWeight="400"
                    textAlign="center"
                >
                    ⚠️ Some of our AI Models are having issues - we are working on fixing them
                </Tag>
            </Box>  */}
        </Box>
    )
}