import { memo, useState, useEffect } from "react";
import {
  useMediaQuery,
  Flex,
  useColorModeValue,
  Divider,
  useColorMode
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Header } from "./header";
import { MainNavItems } from "./mainNavItems";
import { FooterMemoed } from "./footer";
import { useWindowWidth } from '@react-hook/window-size';

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  credits?: number;
  creditsAuto?: number;
  isMinimalist?: boolean;
}

function isEqual(prevProps, nextProps) {
  if (
    prevProps.navSize != nextProps.navSize ||
    prevProps.credits != nextProps.credits ||
    prevProps.creditsAuto != nextProps.creditsAuto ||
    prevProps.screenWidth != nextProps.screenWidth ||
    prevProps.isTooSmall != nextProps.isTooSmall
  ) {
    return false;
  }
  return true;
}

function Sidebar({ navSize, changeNavSize, isMinimalist = false }: Props) {
  // // FORCE NAVSIZE LARGE
  // function Sidebar({ changeNavSize, isMinimalist = false }: Props) {
  // let navSize: "large"|"small" = "large"; // TODO TMP - REMOVE
  // // END FORCE NAVSIZE LARGE
  const initialValues = {
    height: "100%",
    background: useColorModeValue("white", "#1E1E1E"),
    boxShadow: "0 0px 12px 0 rgba(0, 0, 0, 0.2)",
    border: useColorModeValue(
      "border.lightMode.light",
      "border.darkMode.light"
    )
  };
  const [height, setHeight] = useState<string>(initialValues.height);
  const [background, setBackground] = useState<string>(
    initialValues.background
  );
  const [boxShadow, setBoxShadow] = useState<string>(initialValues.boxShadow);
  const [border, setBorder] = useState<string>(initialValues.border);
  const { colorMode } = useColorMode();

  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;
  // const [isTooSmall] = useMediaQuery("(max-width: 800px)");

  const [withShadowFooter, setWithShadowFooter] = useState(false);



  useEffect(() => {
    // console.log("change isTooSmall: " + isTooSmall + " - " + screenWidth);
    if (isTooSmall && navSize === "small") {
      setHeight("60px");
      setBoxShadow("none");
      setBackground("transparent");
      setBorder("transparent");
    } else {
      setHeight(initialValues.height);
      setBoxShadow(initialValues.boxShadow);
      setBackground(initialValues.background);
      setBorder(initialValues.border);
    }
  }, [isTooSmall, navSize, screenWidth, colorMode]);

  useEffect(() => {
    if (!isTooSmall) {
      setBackground(initialValues.background);
    }
  }, [colorMode]);
    
  useEffect(() => {
    // prevent scroll propagation from navbar
    if (navSize === "large") {
      document.body.style.overflow = "hidden";
    }
    if (navSize === "small") {
      document.body.style.overflow = "auto";
    }
  }, [navSize]);
    
  const [expandedAccordion1, setExpandedAccordion1] = useState(false);
  const [expandedAccordion2, setExpandedAccordion2] = useState(false);
  const [expandedAccordion3, setExpandedAccordion3] = useState(false);
  const [expandedAccordionHelp, setExpandedAccordionHelp] = useState(false);

  return (
    <motion.div
      animate={{
        // @ts-ignore
        width: navSize === "small" ? "60px" : isTooSmall ? "100%" : "250px",
      }}
      transition={{
        duration: 0.1,
        ease: "easeOut",
      }}
      style={{
        height,
        position: "fixed",
        top: 0,
        zIndex: 110,
        overflow: "hidden",
        // boxShadow,
      }}
    >
      <Flex
        {...{
          borderWidth: "0px 1px 0px 0px",
          borderStyle: "solid",
          borderColor: border,
        }}
        h="100%"
        w="100%"
        flexDirection="column"
        bg={background}
        onMouseEnter={() => {
          if (!isTooSmall) {
            changeNavSize("large");
          }
        }}
        onMouseLeave={() => {
          if (!isTooSmall) {
            changeNavSize("small");
          }
        }}
      >
        <Flex flexDir="column" h="100%" w="100%">
          <Header
            navSize={navSize}
            isMinimalist={isMinimalist}
            changeNavSize={changeNavSize}
          />
          <MainNavItems
            navSize={navSize}
            isMinimalist={isMinimalist}
            changeNavSize={changeNavSize}
            setWithShadowFooter={setWithShadowFooter}
            {...{
              expandedAccordion1, setExpandedAccordion1,
              expandedAccordion2, setExpandedAccordion2,
              expandedAccordion3, setExpandedAccordion3,
              expandedAccordionHelp, setExpandedAccordionHelp
            }}
          />
          <FooterMemoed
            navSize={navSize}
            isMinimalist={isMinimalist}
            changeNavSize={changeNavSize}
            withShadowFooter={withShadowFooter}
            {...{
              expandedAccordionHelp, setExpandedAccordionHelp
            }}
          />
        </Flex>
      </Flex>
    </motion.div>
  );
}

export default memo(Sidebar, isEqual);
