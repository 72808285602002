import { Icon } from "@chakra-ui/react";

export const CircleInfoSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C12.9062 0 16.5 3.59375 16.5 8C16.5 12.4375 12.9062 16 8.5 16C4.0625 16 0.5 12.4375 0.5 8C0.5 3.59375 4.0625 0 8.5 0ZM8.5 14.5C12.0625 14.5 15 11.5938 15 8C15 4.4375 12.0625 1.5 8.5 1.5C4.90625 1.5 2 4.4375 2 8C2 11.5938 4.90625 14.5 8.5 14.5ZM9.75 10.5C10.1562 10.5 10.5 10.8438 10.5 11.25C10.5 11.6875 10.1562 12 9.75 12H7.25C6.8125 12 6.5 11.6875 6.5 11.25C6.5 10.8438 6.8125 10.5 7.25 10.5H7.75V8.5H7.5C7.0625 8.5 6.75 8.1875 6.75 7.75C6.75 7.34375 7.0625 7 7.5 7H8.5C8.90625 7 9.25 7.34375 9.25 7.75V10.5H9.75ZM8.5 6C7.9375 6 7.5 5.5625 7.5 5C7.5 4.46875 7.9375 4 8.5 4C9.03125 4 9.5 4.46875 9.5 5C9.5 5.5625 9.03125 6 8.5 6Z"
      fill="currentColor"
    />
  </svg>
);
