import { Icon } from "@chakra-ui/react";

export const HeadsetSVGIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 0C12.9688 0 16.3438 3.75 16.5 8V12.5C16.5 14.0312 15.25 15.25 13.75 15.25H10.2812C10.0312 15.7188 9.53125 16 9 16H7.96875C7.15625 16 6.5 15.3438 6.5 14.5C6.5 13.6875 7.15625 13 8 13H9C9.53125 13 10.0312 13.3125 10.2812 13.75H13.75C14.4375 13.75 15 13.2188 15 12.5V11.875C14.75 11.9688 14.4688 12 14.1875 12H13.625C13 12 12.5 11.5 12.5 10.8438V6.6875C12.5 6.03125 13 5.5 13.625 5.5H14.1875C14.3125 5.5 14.4062 5.53125 14.5 5.53125C13.5312 3.1875 11.1875 1.53125 8.5 1.53125C5.78125 1.53125 3.4375 3.1875 2.46875 5.5625C2.5625 5.53125 2.65625 5.5 2.78125 5.5H3.34375C3.96875 5.5 4.5 6.03125 4.5 6.6875V10.8438C4.5 11.5 3.96875 12 3.34375 12H2.78125C1.5 12 0.5 10.9688 0.5 9.6875V8C0.625 3.75 4 0 8.5 0ZM2.78125 7C2.34375 7 2.03125 7.34375 2 7.78125C2 7.875 2 7.9375 2 8V9.6875C2 10.125 2.34375 10.5 2.78125 10.5H3V7H2.78125ZM14.1875 10.5C14.625 10.5 15 10.125 15 9.6875V8C15 7.9375 14.9688 7.875 14.9688 7.78125C14.9375 7.375 14.625 7 14.1875 7H14V10.5H14.1875Z"
      fill="currentColor"
    />
  </svg>
);
