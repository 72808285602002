import React from "react";
import { Icon } from "@chakra-ui/react";

export const LemListIcon = ({ ...props }) => (
  <Icon
    {...{
      width: 42,
      height: 43,
      viewBox: "0 0 42 43",
      ...props,
    }}
  >
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5211 0.5H7.4789C3.34842 0.5 0 3.84841 0 7.9789V35.0211C0 39.1516 3.34842 42.5 7.4789 42.5H34.5211C38.6516 42.5 42 39.1516 42 35.0211V7.9789C42 3.84841 38.6516 0.5 34.5211 0.5Z"
        fill="#316BFF"
      />
      <path
        d="M30.7499 28.6023V30.1475C30.7499 30.756 30.256 31.2499 29.6475 31.2499H15.5681C13.2504 31.1018 11.3983 29.2497 11.2501 26.9319V12.8526C11.2501 12.244 11.744 11.7501 12.3526 11.7501H13.8977C14.5063 11.7501 15.0002 12.244 15.0002 12.8526V24.6336C15.0002 26.2176 16.2825 27.4999 17.8665 27.4999H29.6475C30.256 27.4999 30.7499 27.9938 30.7499 28.6023Z"
        fill="white"
      />
      <path
        d="M29.6476 11.7501H19.8527C19.2438 11.7501 18.7502 12.2437 18.7502 12.8526V14.3977C18.7502 15.0066 19.2438 15.5002 19.8527 15.5002H29.6476C30.2564 15.5002 30.75 15.0066 30.75 14.3977V12.8526C30.75 12.2437 30.2564 11.7501 29.6476 11.7501Z"
        fill="white"
      />
      <path
        d="M27.8978 19.6259H19.8527C19.2438 19.6259 18.7502 20.1195 18.7502 20.7283V22.2735C18.7502 22.8824 19.2438 23.3759 19.8527 23.3759H27.8978C28.5066 23.3759 29.0002 22.8824 29.0002 22.2735V20.7283C29.0002 20.1195 28.5066 19.6259 27.8978 19.6259Z"
        fill="white"
      />
    </svg>
  </Icon>
);
